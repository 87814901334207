import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image"
import Layout from "../components/layout";
import SEO from "../components/seo";
import icon from "../images/icon-contact.svg";

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Contacts = ({ data }) => {
  const Contacts = data.allContentfulPerson.edges;
  return (
    <Layout>
      <SEO title="Lawtech Hub Contacts" />
      <div className="">
        <Img
          className="object-cover w-full h-64"
          fluid={data.file.childImageSharp.fixed}
          alt="Header image"
        />
      </div>
      <div className="container">
      <h1 className="title">Contact</h1>
      </div>
      <div className="bg-white w-full border-t-2 border-b border-white">
        <div className="container flex py-6 my-2 self-center">
          <div className="w-auto lg:w-1/2 \ self-center">
            <h3 className="pt-6 text-center md:text-left">
              {documentToReactComponents(data.contentfulPageIntro.intro.json)}
            </h3>
          </div>
          <div className="w-1/2 hidden lg:block">
            <img src={icon} className="h-64 mb-0 float-right mx-12" alt="LawTech Hub Icons" />
          </div>
        </div>
      </div>


      <div className="container mt-12">

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 -mx-2 my-24">
          {Contacts.map(({ node: post }) => (


            <div key={post.id} className="flex bg-white shadow-sm mb-8 mx-2 rounded-sm">

              <div className="relative px-8 pb-8 pt-10 text-xs text-center leading-4 w-full">

                <div className="relative mx-auto h-24 w-24 -mt-20 border-white rounded-full overflow-hidden border-4">
                  <Img
                    className="object-cover w-full h-full"
                    fixed={post.image.fixed}
                    title={post.name}
                    alt={post.name}

                  />
                </div>
                <div className="text-sm font-extrabold text-purple-800 mt-4 mb-2">{post.name}</div>
                <div className="font-bold text-black my-2">{post.title}</div>
                <div className="my-2">{post.company}</div>
                <div className="mt-6 text-purple-600"><a href={"mailto:" + post.email}>{post.email}</a></div>
                <div className="my-1">{post.phone}</div>
                <div className="my-8 leading-5">{post.shortBio.shortBio}</div>
              </div>
            </div>





          ))}
        </div>




      </div>



    </Layout>
  );
};
export default Contacts;






export const query = graphql`
  query ContactQuery {
    contentfulPageIntro(page: { eq: "Contact" }) {
      intro {
        json
      } 
    }
    allContentfulPerson(filter: {contactPageInclude: {eq: true}}, sort: {fields: [company, name] order: [ASC, ASC] })
    {
      edges {
        node {
          id
          name
          title
          company
          shortBio {
            shortBio
          }
          email
          phone
          image {
            fixed(width: 100) {
              base64
              src
              srcSet
              width
              height
            }
          }  
        }
      }
    }
    file(relativePath: { eq: "banner-contact.jpg" }) {
      childImageSharp {
        fixed(width: 1200) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }    
  }



`;